import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Stars } from "../icons/stars"
import { StaticImage } from "gatsby-plugin-image"
import { GreenButton } from "./button-green"
import { GreenButtonRedirect } from "./button-green-redirect"

export class ReviewsSlider extends Component {
    render() {
        const settings = {
            arrows: true,
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 4000,
            autoplay: true,
            swipe: true,
        }

        const settingsBig = {
            arrows: true,
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplaySpeed: 4000,
            autoplay: true,
            swipe: true,
        }

        return (
            <div className="bg-bggray">
                <div className="md:hidden px-5 pt-10 pb-5 md:py-10">
                    <div className="max-w-5xl mx-auto">
                        <h3 className="font-bold text-2xl text-center">What Our Customers Are Saying</h3>
                        <div className="py-8 px-5 max-w-lg mx-auto">
                            <Slider {...settings}>
                                <div className="px-5">
                                    <p className="py-2">"The guys were great and the price was reasonable. They provided a great service in a timely fashion and completed the project as they said they would. My slightly sketchy access did not deter them. I highly recommend!"</p>
                                    <Stars />
                                    <p className="pt-2">- Adam Wells</p>
                                </div>
                                <div className="px-5">
                                    <p className="py-2">"I will definitely being using this business, Junk Haul, again. They were prompt, professional and went above and beyond. They hauled away what was asked and moved furniture for me. The whole transaction was very easy."</p>
                                    <Stars />
                                    <p className="pt-2">- Jen Lee</p>
                                </div>
                                <div className="px-5">
                                    <p className="py-2">"My wife and I had an opportunity to work with Michael and his crew. They’re on time, respectful, clean and understanding. Definitely a thumbs up from the both of us."</p>
                                    <Stars />
                                    <p className="pt-2">- Dima Dushkov</p>
                                </div>
                                <div className="px-5">
                                    <p className="py-2">"This was a great experience and out of all the others that I got quotes for this company beat out the competition by over 100$... They were very quick and observant to make sure not to break or damage anything."</p>
                                    <Stars />
                                    <p className="pt-2">- Chad Farley</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="hidden md:block px-5 py-5 md:py-10">
                    <div className="max-w-5xl mx-auto">
                        <h3 className="font-bold text-2xl text-center">What Our Customers Are Saying</h3>
                        <div className="py-8 px-5">
                            <Slider {...settingsBig}>
                                <div className="px-5">
                                    <p className="py-2">"The guys were great and the price was reasonable. They provided a great service in a timely fashion and completed the project as they said they would. My slightly sketchy access did not deter them. I highly recommend!"</p>
                                    <Stars />
                                    <p className="pt-2">- Adam Wells</p>
                                </div>
                                <div className="px-5">
                                    <p className="py-2">"I will definitely being using this business, Junk Haul, again. They were prompt, professional and went above and beyond. They hauled away what was asked and moved furniture for me. The whole transaction was very easy."</p>
                                    <Stars />
                                    <p className="pt-2">- Jen Lee</p>
                                </div>
                                <div className="px-5">
                                    <p className="py-2">"My wife and I had an opportunity to work with Michael and his crew. They’re on time, respectful, clean and understanding. Definitely a thumbs up from the both of us."</p>
                                    <Stars />
                                    <p className="pt-2">- Dima Dushkov</p>
                                </div>
                                <div className="px-5">
                                    <p className="py-2">"This was a great experience and out of all the others that I got quotes for this company beat out the competition by over 100$... They were very quick and observant to make sure not to break or damage anything."</p>
                                    <Stars />
                                    <p className="pt-2">- Chad Farley</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto flex px-5 pb-12 pt-5 md:pt-0">
                    <div className="grid space-y-5 mx-auto md:space-x-5 md:flex md:space-y-0">
                        <div className="max-w-lg mx-auto border shadow-xl rounded-lg flex p-5 items-center space-x-5 bg-white">
                            <div className="w-1/2">
                                <StaticImage src="../images/request.png" placeholder="none" className="rounded-t-lg z-0" title="Contact 1st Call Junk Haul Sacramento" alt="Contact 1st Call Junk Haul Sacramento" />
                            </div>
                            <div className="px-2">
                                <p className="text-xl font-bold text-green">Have Any Questions?</p>
                                <p className="text-sm pt-10">Get your questions, concerns or doubts answered within minutes!</p>
                                <div className="pt-10">
                                    <GreenButton buttonPage="/contact" buttonText="Contact Us" />
                                </div>
                            </div>
                        </div>

                        <div className="max-w-lg mx-auto border shadow-xl rounded-lg flex p-5 items-center space-x-5 bg-green text-white">
                            <div className="w-1/2">
                                <StaticImage src="../images/calendar.png" placeholder="none" className="rounded-t-lg z-0" title="Book An Appointment" alt="Book An Appointment" />
                            </div>
                            <div className="px-2">
                                <p className="text-xl font-bold">Ready To Book A Service?</p>
                                <p className="text-sm pt-10">Reclaim your space and let 1st Call Junk Haul Sac do the heavy lifting!</p>
                                <div className="pt-10">
                                    <GreenButtonRedirect buttonText='Book Now' whiteColor={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
