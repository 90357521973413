import React from "react"
import { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"

export class WhyUsSection extends Component {
    render() {
        return (
            <div className="container mx-auto mt-10 px-5">
                <div className="mx-auto text-center">
                    <span className="text-2xl font-bold ">Why Choose Us?</span>
                    <p className="max-w-2xl mx-auto pt-5">1st Call Junk Haul Sacramento is a 5 star rated, local Sacramento Junk Haul and Junk Removal company. Our priority is providing you with expert services which revolve around your needs and your schedule.
                        You can count on us to get the job done, while providing you with a fun and pleasant experience.
                    </p>
                </div>

                <div className="py-5">
                    <div className="grid lg:grid-cols-3 py-5 text-xs md:text-sm justify-center text-center max-w-sm lg:max-w-7xl mx-auto space-y-7 lg:space-y-0">
                        <div className="max-w-xs mx-auto">
                            <StaticImage src="../images/place.png" title="Local Business - 1st Call Junk Haul Sacramento" alt="Local Business - 1st Call Junk Haul Sacramento" className="w-14" />
                            <p className="pt-2 font-bold">Local Business</p>
                            <p>We provide the Greater Sacramento area with Junk Haul and Junk Removal services and unlike big corporate companies, we value and prioritize our customers and their needs</p>
                        </div>

                        <div className="max-w-xs mx-auto">
                            <StaticImage src="../images/coins.png" title="Upfront Pricing - 1st Call Junk Haul Sacramento" alt="Upfront Pricing - 1st Call Junk Haul Sacramento" className="w-14" />
                            <p className="pt-2 font-bold">Upfront Pricing</p>
                            <p>No hidden costs, fees, or prices that do not make sense. We offer free & no obligation estimates before any work is started </p>
                        </div>

                        <div className="max-w-xs mx-auto">
                            <StaticImage src="../images/calendar-icon.png" title="Online Booking - 1st Call Junk Haul Sacramento" alt="Online Booking - 1st Call Junk Haul Sacramento" className="w-14" />
                            <p className="pt-2 font-bold">Online Booking</p>
                            <p>Our online booking makes it quick and easy for you to schedule your Sacramento Junk Haul or Junk Removal services without much effort</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

