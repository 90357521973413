import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HomeMainSection } from "../components/homepage-main-section"
import { HomeCards } from "../components/home-cards"
import { WhyUsSection } from "../components/home-page-WhyUs"
import { ReviewsSlider } from "../components/reviews-slider"

const IndexPage = (props) => {
  return (
    <Layout>
      <Seo
        title="Sacramento - Junk Removal and Junk Hauling Services"
        description="1st Call Junk Haul Sacramento is the best Sacramento Junk Removal and Junk Haul company. We offer services regarding Junk Removal, Dirt & Rock Removal, Green Waste Removal, Property Cleanout, and Commercial Services. We guarantee to provide the highest quality of work with the best prices in town!"
        keywords={["Sacramento Junk Removal", "Sacramento Junk Haul", "Sacramento Dirt & Rock Removal", "Sacramento Green Waste Removal", "Sacramento Property Cleanout", "Sacramento Commercial Services"]}
        location={props.location.pathname}
      />
      <HomeMainSection />
      <HomeCards />
      <WhyUsSection />
      <ReviewsSlider />
    </Layout>
  )
}
export default IndexPage
