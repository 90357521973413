import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Component } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export class HomeMainSection extends Component {
    render() {
        return (
            <div>
                <div className="relative container mx-auto text-white">
                    <div className="absolute z-20 inset-x-0 pt-12 sm:pt-20 md:pt-24 lg:pt-20 lg:pb-24 px-10 text-center lg:text-left pl-10">
                        <h1 className="font-bold text-3xl md:text-5xl mx-auto">
                            1st Call Junk Haul Sacramento
                        </h1>
                        <p className="pt-5 md:pt-10 md:text-md lg:text-lg max-w-lg md:max-w-2xl mx-auto lg:mx-0">
                            We have the equipment, expertise & man-power to deal with jobs of any scale. Our Sacramento Junk Haul and Junk Removal services are available for you, whenever you need & at an affordable rate! Our Sacramento Junk Haul & Junk Removal services include:
                        </p>
                        <ul className="pl-1 pt-5 space-y-2 md:text-md lg:text-lg">
                            <li>• Junk Hauling</li>
                            <li>• Dirt & Rock Removal</li>
                            <li>• Green Waste Removal</li>
                            <li>• Property Cleanout</li>
                            <li>• Commercial Services</li>
                        </ul>
                        <AnchorLink to="/#services" stripHash title="View Services" alt="View Services offered by 1st Call Junk Haul Sacramento" >
                            <div className="mt-8">
                                <button className="relative h-10 py-2 px-5 mx-auto transition-colors rounded-lg border border-green focus:shadow-outline bg-green text-white hover:shadow-lg hover:shadow-green font-semibold transition duration-150 ease-in-out">
                                    <span>View Services</span>
                                </button>
                            </div>
                        </AnchorLink>
                        <div className="pt-7">
                            <p>Call/Text Us Today!</p>
                            <a className="text-lg md:text-xl font-bold hover:text-green" href="tel:916-591-3803" title="Call 1st Call Junk Haul Sacramento" alt="Call 1st Call Junk Haul Sacramento">(916)591-3803</a>
                        </div>
                    </div>
                </div>
                <StaticImage src="../images/home-page.png" placeholder="none" className="w-screen h-[650px] md:h-[700px]" title="1st Call Junk Haul Sacramento - Professional Junk Hauling & Junk Removal" alt="1st Call Junk Haul Sacramento - Professional Junk Hauling & Junk Removal" />
            </div>
        )
    }
}
