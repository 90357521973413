import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Component } from "react"
import { GreenButton } from "./button-green"
import { GreenButtonRedirect } from "./button-green-redirect"

export class HomeCards extends Component {
    render() {
        return (
            <div className="bg-bggray text-center pt-12 pb-4" id="services">
                <div className="px-5 pb-4 sm:pb-8 container mx-auto grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-y-5 gap-x-5">
                    <article>
                        <div className="max-w-sm md:max-w-lg mx-auto border shadow-xl rounded-lg divide-y-4 divide-green h-96">
                            <StaticImage src="../images/Junk-Removal.png" placeholder="none" className="rounded-t-lg h-48 z-0" title="Sacramento Junk Removal" alt="Sacramento Junk Removal" />
                            <div className="px-2">
                                <div className="h-24">
                                    <h2 className="text-xl font-bold pt-5 md:pt-4">Junk Removal</h2>
                                    <p className="text-sm pt-4 md:pt-2">Remove any junk or garbage that is cluttering your property</p>
                                </div>
                                <div className="pt-8">
                                    <GreenButton buttonPage="/sacramento-junk-removal" buttonText="View Details" />
                                </div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div className="max-w-sm md:max-w-lg mx-auto border shadow-xl rounded-lg divide-y-4 divide-green h-96">
                            <StaticImage src="../images/dirt-and-rock-removal.jpeg" placeholder="none" className="rounded-t-lg h-48 z-0" title="Sacramento Dirt & Rock Removal" alt="Sacramento Dirt & Rock Removal" />
                            <div className="px-2">
                                <div className="h-24">
                                    <h2 className="text-xl font-bold pt-5 md:pt-4">Dirt & Rock Removal</h2>
                                    <p className="text-sm pt-4 md:pt-2">Clear your property from any unwanted dirt or rocks</p>
                                </div>
                                <div className="pt-8">
                                    <GreenButton buttonPage="/sacramento-dirt-and-rock-removal" buttonText="View Details" />
                                </div>
                            </div>
                        </div>
                    </article>
                    <article>
                        <div className="max-w-sm md:max-w-lg mx-auto border shadow-xl rounded-lg divide-y-4 divide-green h-96">
                            <StaticImage src="../images/green-waste-removal.jpeg" placeholder="none" className="rounded-t-lg h-48 z-0" title="Sacramento Green Waste Removal" alt="Sacramento Green Waste Removal" />
                            <div className="px-2">
                                <div className="h-24">
                                    <h2 className="text-xl font-bold pt-5 md:pt-4">Green Waste Removal</h2>
                                    <p className="text-sm pt-4 md:pt-2">Clear your property from any unwanted green waste, no matter the size</p>
                                </div>
                                <div className="pt-8">
                                    <GreenButton buttonPage="/sacramento-green-waste-removal" buttonText="View Details" />
                                </div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div className="max-w-sm md:max-w-lg mx-auto border shadow-xl rounded-lg divide-y-4 divide-green h-96">
                            <StaticImage src="../images/property-cleanout.jpeg" placeholder="none" className="rounded-t-lg h-48 z-0" title="Sacramento Property Cleanout" alt="Sacramento Property Cleanout" />
                            <div className="px-2">
                                <div className="h-24">
                                    <h2 className="text-xl font-bold pt-5 md:pt-4">Property Cleanout</h2>
                                    <p className="text-sm pt-4 md:pt-2">No job is too big or too small, free-up space throughout your property</p>
                                </div>
                                <div className="pt-8">
                                    <GreenButton buttonPage="/sacramento-property-cleanout" buttonText="View Details" />
                                </div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div className="max-w-sm md:max-w-lg mx-auto border shadow-xl rounded-lg divide-y-4 divide-green h-96">
                            <StaticImage src="../images/commercial-services.jpeg" placeholder="none" className="rounded-t-lg h-48 z-0" title="Sacramento Commercial Services" alt="Sacramento Commercial Services" />
                            <div className="px-2">
                                <div className="h-24">
                                    <h2 className="text-xl font-bold pt-5 md:pt-4">Commercial Services</h2>
                                    <p className="text-sm pt-4 md:pt-2">Special rates for businesses with our commercial accounts & vendor connections</p>
                                </div>
                                <div className="pt-8">
                                    <GreenButton buttonPage="/sacramento-commercial-services" buttonText="View Details" />
                                </div>
                            </div>
                        </div>
                    </article>

                    <article>
                        <div className="hidden sm:block max-w-sm md:max-w-lg mx-auto border shadow-xl rounded-lg divide-y-4 divide-green h-96">
                            <StaticImage src="../images/book-now.jpeg" placeholder="none" className="rounded-t-lg h-48 z-0" title="Book An Appointment" alt="Book An Appointment" />
                            <div className="px-2">
                                <div className="h-24">
                                    <h2 className="text-xl font-bold pt-4">Ready To Schedule?</h2>
                                    <p className="text-sm pt-4 md:pt-2">Are you ready to schedule an appointment with 1st Call Junk Haul Sacramento?</p>
                                </div>
                                <div className="pt-8">
                                    <GreenButtonRedirect buttonText='Book Now' />
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div >
        )
    }
}
